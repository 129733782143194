import React, { type PropsWithChildren, useState } from 'react';
import {
  type Control,
  Controller,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { faExclamationCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { submissionMessages } from '@trustyou/survey-manager';
import { StyledFontAwesomeIcon } from '@trustyou/ui';
import { z } from 'zod';

type FormType = Record<string, string | number | Date>;

type Question = {
  id: string;
  label: string;
  type: 'text' | 'number' | 'date' | 'select';
  page: number;
  mandatory?: boolean;
  options?: string[]; // Only for select
  condition?: {
    dependsOn: string; // ID of another question
    value: string | number | Date; // Value to check against
  };
};

const PAGE_SIZE = 2;

const QUESTIONS: Question[] = [
  {
    id: 'q1',
    label: 'Yes or no?',
    type: 'select',
    page: 1,
    options: ['yes', 'no'],
  },
  {
    id: 'q2',
    label: 'Why yes?',
    type: 'text',
    page: 2,
    condition: { dependsOn: 'q1', value: 'yes' },
  },
  {
    id: 'q3',
    label: 'Why not?',
    type: 'text',
    page: 2,
    mandatory: true,
    condition: { dependsOn: 'q1', value: 'no' },
  },
];

export const POCChatGPT = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(QUESTIONS.length / PAGE_SIZE);

  const errorMessages = useErrorMessages();

  const { control, handleSubmit, trigger, watch } = useForm<FormType>({
    // defaultValues: QUESTIONS.reduce((acc, q) => ({ ...acc, [q.id]: '' }), {}),
    resolver: (data, context, options) => {
      const validationSchema = createValidationSchema(QUESTIONS, watch(), errorMessages);
      return zodResolver(validationSchema)(data, context, options);
    },
    mode: 'onChange',
  });

  const { renderInput } = useInput(control);

  const onValid: SubmitHandler<FormType> = (data) => {
    const validData = Object.entries(data).reduce<FormType>((acc, [key, value]) => {
      if (value !== '' && value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    console.info(validData);
  };

  const onInvalid: SubmitErrorHandler<FormType> = (errors) => console.warn(errors);

  const filteredQuestions = QUESTIONS.filter((q) => {
    if (!q.condition) return q.page === currentPage; // No condition, filter by page
    const { dependsOn, value } = q.condition;
    return watch(dependsOn) === value && q.page === currentPage; // Check the condition and page
  });

  return (
    <Stack component="form" onSubmit={handleSubmit(onValid, onInvalid)} sx={{ gap: 2, margin: 3 }}>
      {filteredQuestions.map((question) => (
        <React.Fragment key={question.id}>{renderInput(question)}</React.Fragment>
      ))}
      <Stack direction="row" spacing={2}>
        {currentPage > 1 && (
          <Button variant="contained" onClick={() => setCurrentPage((prev) => prev - 1)}>
            Previous
          </Button>
        )}
        {currentPage !== totalPages && (
          <Button
            variant="contained"
            onClick={async () => {
              const valid = await trigger(filteredQuestions.map((q) => q.id));
              if (valid) {
                setCurrentPage((prev) => prev + 1);
              }
            }}
          >
            Next
          </Button>
        )}
        {currentPage === totalPages && (
          <Button type="submit" variant="contained">
            Submit
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

const createValidationSchema = (
  questions: Question[],
  values: FormType,
  errorMessages: Record<string, string>
) => {
  const generalSchema: Record<string, z.ZodTypeAny> = {};

  questions.forEach((question) => {
    let fieldSchema: z.ZodTypeAny;

    // Base type validation
    switch (question.type) {
      case 'text':
        fieldSchema = z.string().optional();
        break;
      case 'number':
        fieldSchema = z.number().optional();
        break;
      case 'date':
        fieldSchema = z.date().optional();
        break;
      default:
        fieldSchema = z.any().optional();
    }

    // Add mandatory validation only if the condition is met
    if (question.mandatory) {
      fieldSchema = fieldSchema.refine((value) => {
        // Check if the condition exists and is not met
        if (question.condition) {
          const { dependsOn, value: conditionValue } = question.condition;
          if (values[dependsOn] !== conditionValue) {
            return true; // Skip validation if the condition is not met
          }
        }
        return value !== undefined && value !== null && value !== '';
      }, errorMessages.mandatory);
    }

    generalSchema[question.id] = fieldSchema;
  });

  return z.object(generalSchema);
};

function useErrorMessages() {
  const intl = useIntl();

  return {
    mandatory: intl.formatMessage(submissionMessages.mandatoryQuestion),
  };
}

const CustomLabel = ({ question }: { question: Question }) => {
  return (
    <InputLabel htmlFor={question.id}>
      {question.label} {question.mandatory && <span style={{ color: 'red' }}>*</span>}
    </InputLabel>
  );
};

const CustomError = ({ errorMessage }: { errorMessage?: string }) => {
  return (
    errorMessage && (
      <Typography variant="caption" color="error">
        <StyledFontAwesomeIcon icon={faExclamationCircle} size="lg" sx={{ marginInlineEnd: 1 }} />
        {errorMessage}
      </Typography>
    )
  );
};

const CustomField = ({
  question,
  errorMessage,
  children,
}: {
  question: Question;
  errorMessage?: string;
} & PropsWithChildren) => {
  return (
    <Stack spacing={1}>
      <CustomLabel question={question} />
      {children}
      <CustomError errorMessage={errorMessage} />
    </Stack>
  );
};

function useInput(control: Control) {
  const SELECT_PLACEHOLDER = 'Select an option';

  const renderInput = (question: Question) => {
    switch (question.type) {
      case 'text':
        return (
          <Controller
            name={question.id}
            control={control}
            render={({ field, fieldState }) => (
              <CustomField question={question} errorMessage={fieldState.error?.message}>
                <TextField {...field} id={question.id} error={!!fieldState.error} />
              </CustomField>
            )}
          />
        );
      case 'number':
        return (
          <Controller
            name={question.id}
            control={control}
            render={({ field, fieldState }) => (
              <CustomField question={question} errorMessage={fieldState.error?.message}>
                <TextField {...field} id={question.id} error={!!fieldState.error} type="number" />
              </CustomField>
            )}
          />
        );
      case 'select':
        return (
          <Controller
            name={question.id}
            control={control}
            render={({ field, fieldState }) => (
              <CustomField question={question} errorMessage={fieldState.error?.message}>
                <Select {...field} id={question.id} error={!!fieldState.error} displayEmpty>
                  <MenuItem value="">
                    <em>{SELECT_PLACEHOLDER}</em>
                  </MenuItem>
                  {question.options?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </CustomField>
            )}
          />
        );
      default:
        return null;
    }
  };

  return { renderInput };
}
