import type { FormValues } from '../survey';
import type { Content } from '../types';
import { isQuestion, isSection } from '../utils/type-narrowing';
import { hasActiveCondition } from '../utils/validation';

export function useVisibleItems(contentItems: Content[], watchedValues: FormValues) {
  const shouldRender = (content: Content) => {
    // Render anything that is not a question or a section.
    if (!isQuestion(content) && !isSection(content)) {
      return true;
    }

    // Render the section title only when it has, at least, one visible content item.
    if (isSection(content)) {
      const section = content;
      const sectionIndex = contentItems.findIndex((item) => item.id === section.id);
      for (let i = sectionIndex + 1; i < contentItems.length && !isSection(contentItems[i]); i++) {
        if (shouldRender(contentItems[i])) {
          return true;
        }
      }
      return false;
    }

    // Render questions that have no conditions or the ones that have their conditions active.
    const question = content;
    const condition = question.condition;
    const shouldRenderQuestion = !condition || hasActiveCondition(condition, watchedValues);

    return shouldRenderQuestion;
  };

  const visibleItems = contentItems.filter(shouldRender);

  return visibleItems;
}
