import { useEffect, useMemo } from 'react';

import createCache from '@emotion/cache';
import { getFontFamily, isRTL, useLanguageStore } from '@trustyou/shared';
import { createTheme, darken, theme as defaultTheme, lighten, useTheme } from '@trustyou/ui';
import deepmerge from 'deepmerge';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import type { Questionnaire } from '../client';
import { useSurveyThemeStore } from '../store';
import { DEFAULT_THEME } from '../utils';

export const useSurveyTheme = (questionnaireTheme?: Questionnaire['theme']) => {
  const { colors, fonts } = questionnaireTheme ?? DEFAULT_THEME;
  const { locale } = useLanguageStore();
  const muiTheme = useTheme();
  const { surveyTheme: theme, setSurveyTheme } = useSurveyThemeStore();

  const direction = isRTL(locale) ? 'rtl' : 'ltr';

  const accentColor = colors.accent ?? muiTheme.palette.primary.main;
  const buttonTextColor = colors.button_text ?? muiTheme.palette.primary.contrastText;
  const primaryTextColor = colors.primary_text ?? muiTheme.palette.text.primary;
  const secondaryTextColor = colors.secondary_text ?? muiTheme.palette.text.secondary;
  const headingsColor = colors.headings ?? muiTheme.palette.text.primary;
  const backgroundColor = colors.background ?? muiTheme.palette.background.default;
  const foregroundColor = colors.foreground ?? muiTheme.palette.background.paper;

  const headingsFontFamily = getFontFamily(fonts.headings);
  const bodyTextFontFamily = getFontFamily(fonts.body_text);

  const customTheme = createTheme(
    deepmerge(defaultTheme, {
      direction,
      palette: {
        primary: {
          main: accentColor,
          light: lighten(accentColor, 0.1),
          dark: darken(accentColor, 0.1),
          // TODO: Challenge the design towards using the auto-calculated contrasted text color instead of a fixed one defined in the CXP Survey Theme Editor (colors.button_text).
          // contrastText: theme.palette.getContrastText(colors.primary),
          // contrastText: buttonTextColor,
        },
        text: {
          primary: primaryTextColor,
          secondary: secondaryTextColor,
        },
        background: {
          default: backgroundColor,
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              whiteSpace: 'pre-wrap',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontFamily: bodyTextFontFamily,
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              fontFamily: bodyTextFontFamily,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: foregroundColor,
            },
          },
        },
      },
      typography: {
        h1: {
          color: headingsColor,
          fontFamily: headingsFontFamily,
        },
        h2: {
          color: headingsColor,
          fontFamily: headingsFontFamily,
        },
        h3: {
          color: headingsColor,
          fontFamily: bodyTextFontFamily,
        },
        h4: {
          color: headingsColor,
          fontFamily: headingsFontFamily,
        },
        h5: {
          color: headingsColor,
          fontFamily: headingsFontFamily,
        },
        h6: {
          color: headingsColor,
          fontFamily: headingsFontFamily,
        },
        subtitle1: {
          color: primaryTextColor,
          fontFamily: bodyTextFontFamily,
        },
        subtitle2: {
          color: primaryTextColor,
          fontFamily: bodyTextFontFamily,
        },
        body1: {
          color: primaryTextColor,
          fontFamily: bodyTextFontFamily,
        },
        body2: {
          color: primaryTextColor,
          fontFamily: bodyTextFontFamily,
        },
        button: {
          color: buttonTextColor,
          fontFamily: bodyTextFontFamily,
        },
        caption: {
          color: secondaryTextColor,
          fontFamily: bodyTextFontFamily,
        },
        overline: {
          color: primaryTextColor,
          fontFamily: bodyTextFontFamily,
        },
      },
    })
  );

  const cache = useMemo(
    () =>
      createCache({
        key: direction === 'rtl' ? 'muirtl' : 'mui',
        stylisPlugins: direction === 'rtl' ? [rtlPlugin, prefixer] : [prefixer],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [direction, theme]
  );

  useEffect(() => {
    setSurveyTheme(customTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    surveyTheme: customTheme,
    cache,
  };
};
