export function denormalizeScore(score: number, targetScale: number) {
  const scaleFactor = 100 / targetScale;
  return Math.ceil(score / scaleFactor);
}

export function convertScaleQuestionColor(color: string) {
  switch (color) {
    case '#e53835':
      return 'error';
    case '#ef6c00':
      return 'warning';
    case '#388e3c':
      return 'success';
    default:
      return 'inherit';
  }
}
