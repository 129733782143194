import type { Theme } from '@mui/material';
import chroma from 'chroma-js';

const getLAB = (hexColor: string) => {
  return chroma(hexColor).lab();
};

const calculateColorDistance = (color1: string, color2: string) => {
  const lab1 = getLAB(color1);
  const lab2 = getLAB(color2);
  return Math.sqrt(
    Math.pow(lab1[0] - lab2[0], 2) + Math.pow(lab1[1] - lab2[1], 2) + Math.pow(lab1[2] - lab2[2], 2)
  );
};

const hexToRgb = (hex: string) => {
  hex = hex.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

const calculateLuminance = (r: number, g: number, b: number) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const isDarkColor = (hex: string) => {
  const [r, g, b] = hexToRgb(hex);
  const luminance = calculateLuminance(r, g, b);
  return luminance < 0.5;
};

const checkColorSimilarity = (color1: string, color2: string) => {
  const distance = calculateColorDistance(color1, color2);
  const isSimilar = distance < 18;
  return isSimilar;
};

export function getDynamicTextColor(theme: Theme) {
  const backgroundColor = theme?.palette.background.default;
  const textColor = theme?.palette.text.primary;

  const isTextSimilarToBackground = checkColorSimilarity(textColor, backgroundColor);

  const dynamicTextColor = isTextSimilarToBackground
    ? theme?.palette.getContrastText(backgroundColor)
    : textColor;

  return dynamicTextColor;
}
