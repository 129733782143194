import { useIntl } from 'react-intl';

import { SurveyMessageContent, submissionMessages } from '@trustyou/survey-manager';

import type { ThankyouMessage } from './client';

type ThankYouProps = {
  message: ThankyouMessage;
  language: string;
};

export function ThankYou({ message, language }: ThankYouProps) {
  const intl = useIntl();

  return (
    <SurveyMessageContent
      title={message.title?.[language] ?? intl.formatMessage(submissionMessages.defaultTitle)}
      description={
        message.description?.[language] ?? intl.formatMessage(submissionMessages.defaultDescription)
      }
    />
  );
}
