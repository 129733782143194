import type { RouteObject } from 'react-router-dom';

import { POCChatGPT } from './POC-chatgpt';
import ErrorPage from './error-page';
import Unsubscribe from './unsubscribe';

import Survey from './survey';

export const routes: RouteObject[] = [
  // The Proof of Concept (POC) component is useful for exploring and testing purposes with a simpler form.
  {
    path: '/poc',
    element: <POCChatGPT />,
  },
  {
    path: '/:organizationId/:entityId/:surveyId/',
    element: <Survey />,
    errorElement: <ErrorPage />,
    loader: Survey.loader,
  },
  {
    path: '/unsubscribe/:distributionId',
    element: <Unsubscribe />,
  },
];
