import { useIntl } from 'react-intl';

import { NativeSelect, OutlinedInput } from '@mui/material';
import { FormControl } from '@trustyou/ui';

import type { Language } from '../../../../client';

export type LanguageOption = {
  value: Language;
  label: string;
};

export type LanguageSelectorProps = {
  options: LanguageOption[];
  value: Language;
  onChange: (locale: Language) => void;
};

export function SurveyLanguageSelector({ options, value, onChange }: LanguageSelectorProps) {
  const intl = useIntl();

  return (
    <FormControl>
      <NativeSelect
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value as Language);
        }}
        input={<OutlinedInput />}
        inputProps={{
          'aria-label': intl.formatMessage({
            id: 'survey.language-selector.label',
            defaultMessage: 'Language',
          }),
        }}
        sx={{
          width: 180,
          color: 'initial',
          backgroundColor: 'background.paper',
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
}
