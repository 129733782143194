import { useIntl } from 'react-intl';

import { Box, Stack, TrustYouLogo, Typography } from '@trustyou/ui';

import { submissionMessages } from '../../../../i18n-submission-ui-subset/messages';

export type SurveyFooterProps = {
  entity: {
    id?: string;
    name?: string | null;
    brand?: string | null;
    continent?: string | null;
    country?: string | null;
    city?: string | null;
    address?: string | null;
    type?: string | null;
    state?: string | null;
    zip?: string | null;
  };
  textColor: string;
  hasDarkBackground: boolean;
};

export function SurveyFooter({ entity, textColor, hasDarkBackground }: SurveyFooterProps) {
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2,
        justifyContent: { sm: 'space-between' },
        alignItems: { xs: 'center', sm: 'end' },
        '& .MuiTypography-root': {
          color: textColor,
        },
      }}
    >
      <Stack sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
        <Box sx={{ paddingBlockEnd: entity?.name || entity?.address ? 2 : 0 }}>
          <Typography variant="body2">
            {intl.formatMessage(submissionMessages.mandatoryQuestionsDisclaimer)}
          </Typography>
        </Box>
        {entity?.name && <Typography variant="subtitle2">{entity.name}</Typography>}
        {entity?.address && (
          <Typography variant="body2">
            {entity.address}, {entity.city} {entity.zip}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="caption">{intl.formatMessage(submissionMessages.by)}</Typography>
        <Box sx={{ display: 'flex', width: 120, height: 13 }}>
          {hasDarkBackground ? (
            <TrustYouLogo variant="full" color="white" />
          ) : (
            <TrustYouLogo variant="full" color="blue" />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
