import { useEffect, useState } from 'react';

export function useCountdown(initialSeconds: number) {
  const [remainingSeconds, setRemainingSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingSeconds((prevRemainingSeconds) => prevRemainingSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return remainingSeconds;
}
