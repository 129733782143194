import { IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ignoreMissingTranslationWarnings, useLanguageStore } from '@trustyou/shared';
import { submissionUiSubsetMessages } from '@trustyou/survey-manager';
import { SnackbarProvider } from '@trustyou/ui';

import { useSurveyTheme } from './hooks/use-survey-theme';
import { routes } from './routes';

import { useSurveyThemeStore } from './store';

const queryClient = new QueryClient();

const router = createBrowserRouter(routes, {
  basename: `/${process.env.REACT_APP_ROUTER_BASENAME}`,
});

export function App() {
  const { locale } = useLanguageStore();
  const { cache } = useSurveyTheme();
  const surveyTheme = useSurveyThemeStore((state) => state.surveyTheme);

  return (
    <IntlProvider
      onError={ignoreMissingTranslationWarnings}
      locale={locale}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      messages={submissionUiSubsetMessages[locale as keyof typeof submissionUiSubsetMessages]}
    >
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={surveyTheme}>
            <SnackbarProvider>
              <CssBaseline />
              <RouterProvider router={router} />
            </SnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}
