import { LANGUAGE_OPTIONS, submissionUiSubsetMessages } from '@trustyou/survey-manager';

import type { Language } from '../client';

export function getLanguageOptions(availableLanguages: Language[]) {
  return LANGUAGE_OPTIONS.filter((option) => availableLanguages.includes(option.value));
}

export function findLocale(shortLanguageCode: string) {
  return (
    Object.keys(submissionUiSubsetMessages).find((languageCode) =>
      languageCode.startsWith(shortLanguageCode)
    ) ?? shortLanguageCode
  );
}
