import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import type { CardMediaProps } from '@mui/material';
import { Box, CardMedia, Stack, Typography } from '@trustyou/ui';

import { messages } from '../../../../utils/messages';
import { MAX_HEADER_IMAGE_HEIGHT_PX } from '../../constants';

type ImageType = {
  url: string;
  variant?: 'banner' | 'logo';
};

type SurveyHeaderProps = {
  image?: ImageType;
  entityName?: string | null;
  pagination?: ReactNode;
};

export function SurveyHeader({ image, entityName, pagination }: SurveyHeaderProps) {
  return (
    <>
      {image && <HeaderImage image={image} />}
      <Stack spacing={2} sx={{ textAlign: 'center' }}>
        {entityName && (
          <Typography component="h1" variant="h5">
            {entityName}
          </Typography>
        )}
        {pagination}
      </Stack>
    </>
  );
}

function HeaderImage({ image }: { image: ImageType }) {
  return (
    <Box sx={{ marginInline: -5, marginBlockStart: -5 }}>
      {image.variant === 'banner' && (
        <CustomCardMedia
          url={image.url}
          variant={image.variant}
          sx={{
            objectFit: 'contain',
          }}
        />
      )}
      {image.variant === 'logo' && (
        <Box
          sx={{
            height: MAX_HEADER_IMAGE_HEIGHT_PX,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCardMedia
            url={image.url}
            variant={image.variant}
            sx={{
              maxHeight: MAX_HEADER_IMAGE_HEIGHT_PX / 2,
              objectFit: 'contain',
            }}
          />
        </Box>
      )}
    </Box>
  );
}

function CustomCardMedia({ url, variant, sx }: CardMediaProps & ImageType) {
  const intl = useIntl();

  return (
    <CardMedia
      component="img"
      alt={intl.formatMessage(messages.surveyHeaderImageAlt)}
      image={url}
      data-image-variant={variant}
      sx={sx}
    />
  );
}
