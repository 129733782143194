import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useCountdown } from '@trustyou/shared';
import { submissionMessages } from '@trustyou/survey-manager';
import { Typography } from '@trustyou/ui';

import { SECONDS_TO_AUTO_RELOAD } from './utils';

type AutoReloadCountdownProps = {
  textColor: string;
};

export function AutoReloadCountdown({ textColor }: AutoReloadCountdownProps) {
  const intl = useIntl();
  const remainingSeconds = useCountdown(SECONDS_TO_AUTO_RELOAD);
  useAutoReload(remainingSeconds);

  return (
    <Typography variant="caption" sx={{ color: textColor }}>
      {intl.formatMessage(submissionMessages.autoReload, { seconds: remainingSeconds })}
    </Typography>
  );
}

function useAutoReload(remainingSeconds: number) {
  useEffect(() => {
    if (remainingSeconds === 0) {
      window.location.reload();
    }
  }, [remainingSeconds]);
}
