/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { supportedLanguages } from '@trustyou/shared';
import { SurveyLanguageSelector } from '@trustyou/survey-manager';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  SnackbarProvider,
  Stack,
  Typography,
  personHoldingACoffeeCup,
  personShowingOkSign,
  snackbar,
} from '@trustyou/ui';

import type { Language } from './client';

import { getSurveyEmailUnsubscribeStatus, unsubscribeSurveyEmail } from './service/api';

export default function Unsubscribe() {
  const intl = useIntl();
  const [language, setLanguage] = useState<Language>('en');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isAlreadyUnsubscribed, setIsAlreadyUnsubscribed] = useState(false);
  const { distributionId = '' } = useParams();

  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang.value as Language,
    label: lang.label,
  }));

  useEffect(() => {
    async function checkIfUserAlreadyUnsubscribed() {
      setIsLoading(true);
      try {
        const status = await getSurveyEmailUnsubscribeStatus(distributionId);
        if (status === 200) {
          setIsAlreadyUnsubscribed(true);
        } else {
          snackbar.error(
            intl.formatMessage({
              id: 'survey.email-unsubscribe.error',
              defaultMessage: 'Operation unsuccessful, please try again',
            })
          );
        }
      } catch (error) {
        console.error('Failed to get Unsubscribe status.', error);
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkIfUserAlreadyUnsubscribed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function confirmUnsubscribe() {
    setIsLoading(true);
    try {
      const status = await unsubscribeSurveyEmail(distributionId);
      if (status === 201) {
        setIsSuccessful(true);
      } else {
        snackbar.error(
          intl.formatMessage({
            id: 'survey.email-unsubscribe.error',
            defaultMessage: 'Operation unsuccessful, please try again',
          })
        );
      }
    } catch (error) {
      console.error('Failed to Unsubscribe email.', error);
      snackbar.error(
        intl.formatMessage({
          id: 'survey.email-unsubscribe.error',
          defaultMessage: 'Operation unsuccessful, please try again',
        })
      );
    }
    setIsLoading(false);
  }

  return (
    <SnackbarProvider>
      <Dialog fullScreen open={true}>
        <Stack sx={{ alignSelf: 'end', padding: 2 }}>
          <SurveyLanguageSelector
            options={languageOptions}
            value={language}
            onChange={setLanguage}
          />
        </Stack>
        <Stack spacing={4} sx={{ alignItems: 'center', height: '100%', justifyContent: 'center' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {isAlreadyUnsubscribed ? (
                <AlreadyUnsubscribed />
              ) : (
                <>
                  {isSuccessful ? (
                    <UnsubscribedSuccessful />
                  ) : (
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    <UnsubscribeConfirmation onConfirm={confirmUnsubscribe} />
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </Dialog>
    </SnackbarProvider>
  );
}

function UnsubscribeConfirmation({ onConfirm }: { onConfirm: () => void }) {
  return (
    <>
      <Box
        component="img"
        sx={{
          height: 174,
          width: 283,
        }}
        alt="person-holding-coffee-cup"
        src={personHoldingACoffeeCup}
      />
      <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
        <FormattedMessage
          id="survey.email-unsubscribe.confirmation"
          defaultMessage="Please confirm below that you want to unsubscribe from receiving our survey request emails"
        />
      </Typography>
      <Button variant="contained" onClick={onConfirm}>
        <FormattedMessage id="survey.common.unsubscribe" defaultMessage="Unsubscribe" />
      </Button>
    </>
  );
}

function UnsubscribedSuccessful() {
  return (
    <>
      <Box
        component="img"
        sx={{
          height: 174,
          width: 283,
        }}
        alt="person-next-broken-light-bulb"
        src={personShowingOkSign}
      />
      <Stack sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
          <FormattedMessage
            id="survey.email-unsubscribe.success.title"
            defaultMessage="Unsubscribe successful"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.email-unsubscribe.not-receive-survey-invitations"
            defaultMessage="You won’t receive any more survey invitations."
          />
        </Typography>
      </Stack>
    </>
  );
}

function AlreadyUnsubscribed() {
  return (
    <>
      <Box
        component="img"
        sx={{
          height: 174,
          width: 283,
        }}
        alt="person-next-broken-light-bulb"
        src={personShowingOkSign}
      />
      <Stack sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
          <FormattedMessage
            id="survey.email-unsubscribe.already-unsubscribe.title"
            defaultMessage="You’re already unsubscribed!"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.email-unsubscribe.not-receive-survey-invitations"
            defaultMessage="You won’t receive any more survey invitations."
          />
        </Typography>
      </Stack>
    </>
  );
}
